export default class AutoSelect {
	constructor(select) {
		this.select = select;
		this.paramName = select.getAttribute("name");
		this.paramsToRemove = ["page"];
		this.init();
	}

	init() {
		let url = new URL(window.location.href);
		let urlParams = new URLSearchParams(url.search);
		let paramValue = urlParams.get(this.paramName);

		// Appliquer la valeur existante de l'URL si présente
		if (paramValue) {
			this.select.value = paramValue;
		}

		// Ajouter l'événement onchange
		this.select.addEventListener("change", () => this.onChange());
	}

	onChange() {
		let newUrl = new URL(window.location.href);
		let newParams = new URLSearchParams(newUrl.search);

		// Ajouter ou mettre à jour le paramètre sélectionné
		newParams.set(this.paramName, this.select.value);

		// Supprimer les paramètres spécifiés dans la liste
		this.paramsToRemove.forEach(param => newParams.delete(param));

		// Mettre à jour l'URL et recharger la page
		newUrl.search = newParams.toString();
		window.location.href = newUrl.toString();
	}
}
