import Amplitude from 'amplitudejs';

export default new class AudioPlayer {
	constructor() {
		this.songsArray = [];
	}

	initializePlayer() {
		this.collectSongs();
		this.initAmplitude();
	}

	collectSongs() {
		const tracks = document.querySelectorAll('.js-track');

		if (tracks.length === 0) {
			return;
		}

		tracks.forEach((track) => {
			const song = {
				name: track.dataset.trackTitle,
				url: track.dataset.trackUrl,
				album: track.dataset.trackAlbum
			};
			this.songsArray.push(song);
		});
	}

	initAmplitude() {
		if (this.songsArray.length === 0) {
			return;
		}
		Amplitude.init({
			"songs": this.songsArray,
			'debug': true,
		});
	}
};
