import MasonryLayout from "masonry-layout";
import imagesLoaded from "imagesloaded";

export default new class Masonry {
	initMasonry() {
		if (document.querySelectorAll('.js-masonry-grid').length > 0) {
			document.querySelectorAll('.js-masonry-grid').forEach((currentMasonry) => {
				imagesLoaded(currentMasonry, function () {
					console.log('all images are loaded');
					new MasonryLayout(currentMasonry, {
						itemSelector: '.js-masonry-grid-item',
						columnWidth: '.js-masonry-grid-sizer',
						percentPosition: true
					});
				});
			});
		}
	}
};
