export default new class MenubarNavigation {
	initResponsiveNav() {
		// Responsive nav
		const header = document.querySelector('.js-site-header');
		// const actions = this.actions;
		const burgerBtn = document.querySelector('.js-open-nav');
		if (burgerBtn) {
			burgerBtn.addEventListener('click', function () {
				if (!burgerBtn.classList.contains('menu-is-opened')) {
					burgerBtn.classList.add('menu-is-opened');
					burgerBtn.classList.remove('menu-is-closed');
					// change aria expanded
					burgerBtn.setAttribute('aria-expanded', 'true');
					document.getElementById(burgerBtn.getAttribute('aria-controls')).focus();
				} else {
					burgerBtn.classList.remove('menu-is-opened');
					burgerBtn.classList.add('menu-is-closed');
					burgerBtn.setAttribute('aria-expanded', 'false');
				}
				header.classList.toggle('menu-is-opened');
			});
		}
	}


};
