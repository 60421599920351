import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default new class Slideshow {
	initSlideshow() {
		if (document.querySelectorAll('.js-swiper').length > 0) {
			document.querySelectorAll('.js-swiper').forEach((currentSlideshow) => {
				const itemsPerPage = currentSlideshow.dataset.itemsperpage;
				const spaceBetween = currentSlideshow.dataset.spacebetween;
				let center = false;
				if (itemsPerPage == 'auto') {
					center = true;
				}

				new Swiper(currentSlideshow, {
					modules: [Navigation, Pagination],
					lazyPreloadPrevNext: 1,
					slidesPerView: itemsPerPage == 'auto' ? 'auto' : 1 + 0.1,
					spaceBetween: spaceBetween ?? 20,
					grabCursor: true,
					centeredSlides: center,
					navigation: {
						nextEl: '.js-swiper-button-next',
						prevEl: '.js-swiper-button-prev',
					},
					pagination: {
						el: ".js-swiper-progressbar",
						type: "progressbar",
					},
					autoHeight: itemsPerPage == 1,
					breakpoints: {
						380: {
							slidesPerView: itemsPerPage == 'auto' ? 'auto' : 1 + 0.4,
							spaceBetween: 20,
						},
						640: {
							slidesPerView: itemsPerPage == 'auto' ? 'auto' : (itemsPerPage - 2 + ((itemsPerPage - 2) / 10)),
							spaceBetween: 20,
						},
						768: {
							slidesPerView: itemsPerPage == 'auto' ? 'auto' : (itemsPerPage - 1),
							spaceBetween: 20,
						},
						1024: {
							slidesPerView: itemsPerPage == 'auto' ? 'auto' : (itemsPerPage - 1),
							spaceBetween: 40,
						},
						1280: {
							slidesPerView: itemsPerPage,
							spaceBetween: 40,
						},
					},
				});
			});
		}
	}
};
