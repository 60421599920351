export default new class Header {
	constructor() {
		this.header = document.querySelector('.js-site-header');
	}

	initScroll(scrollChange) {
		if (!scrollChange) {
			scrollChange = 100;
		}
		let scrollpos = window.scrollY;

		const body = document.querySelector('body');
		const header = this.header;
		const actions = this.actions;

		if (this.header) {
			const addClassOnScroll = function () {
				body.classList.add('scrolled');
				actions && actions.classList.add('stacked');
				header.classList.add('scrolled');
			};

			const removeClassOnScroll = function () {
				body.classList.remove('scrolled');
				header.classList.remove('scrolled');
				if (!(header.classList.contains('is-opened'))) {
					actions && actions.classList.remove('stacked');
				}
			};

			window.addEventListener('scroll', function () {
				scrollpos = window.scrollY;

				if (scrollpos >= scrollChange) { addClassOnScroll(); } else { removeClassOnScroll(); }
			});

			let lastScrollTop;
			window.addEventListener('scroll', function () {
				var scrollTop = window.scrollY || document.documentElement.scrollTop;
				if (scrollTop > lastScrollTop) {
					header.classList.add('is-hidden');
				}
				else {
					header.classList.remove('is-hidden');
				}
				lastScrollTop = scrollTop;
			});
		}
	}
};
