export default class Accordion {
	constructor(domNode) {
		this.rootEl = domNode;

		const controlsId = this.rootEl.getAttribute('aria-controls');
		this.contentEl = document.getElementById(controlsId);

		this.open = this.rootEl.getAttribute('aria-expanded') === 'true';

		// add event listeners
		this.rootEl.addEventListener('click', this.onButtonClick.bind(this));
	}

	onButtonClick() {
		this.toggle(!this.open);
	}

	toggle(open) {
		// don't do anything if the open state doesn't change
		if (open === this.open) {
			return;
		}

		// update the internal state
		this.open = open;

		// handle DOM updates
		this.rootEl.setAttribute('aria-expanded', `${open}`);
		if (open) {
			this.contentEl.removeAttribute('hidden');
		} else {
			this.contentEl.setAttribute('hidden', '');
		}
	}

	// Add public open and close methods for convenience
	open() {
		this.toggle(true);
	}

	close() {
		this.toggle(false);
	}
}
