export default new class Wishlist {
	initWishlistLinks() {

		function getCookie(name) {
			let cookieValue = null;
			if (document.cookie && document.cookie !== '') {
				const cookies = document.cookie.split(';');
				for (let i = 0; i < cookies.length; i++) {
					const cookie = cookies[i].trim();
					if (cookie.substring(0, name.length + 1) === (name + '=')) {
						cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
						break;
					}
				}
			}
			return cookieValue;
		}

		const csrftoken = getCookie('csrftoken');
		const sections = document.querySelectorAll('.js-wish-add-remove');

		sections.forEach(element => {

			element.addRemoveWish = function (url) {
				fetch(url,
					{
						method: "POST",
						headers: {
							"X-CSRFToken": csrftoken,
							"X-Requested-with": "XMLHttpRequest"
						},
					})
					.then(response => {
						return response.json();
					})
					.then(data => {
						if (!data.success)
							this.redirectToLogin();
						this.updateStatus();
					})
					.catch(() => {
						this.redirectToLogin();
					});
			};

			element.redirectToLogin = function () {
				const currentUrl = window.location.href;
				const encodedUrl = encodeURIComponent(currentUrl);
				window.location.href = `${this.dataset.loginUrl}?next=${encodedUrl}`;
			};

			element.updateStatus = function () {
				const props = this.dataset;
				fetch(props.statusUrl)
					.then(response => {
						if (!response.ok) {
							throw new Error('Error fetching $(props.statusUrl): $(response.status)');
						}
						return response.json();
					})
					.then(data => {
						if (data.exists) {
							this.classList.add(props.activeClass);
							const wishRemoveLink = element.querySelector('.js-wish-remove-link');

							if (wishRemoveLink) {
								wishRemoveLink.addEventListener('click', (event) => {
									event.preventDefault();
									element.addRemoveWish(props.removeUrl);

									// Others for same media in same page
									const otherLinks = document.querySelectorAll('.js-wish-add-remove[data-status-url="' + props.statusUrl + '"]');
									const wishRemoveLinks = Array.from(otherLinks).filter(function (item) { return item != element; });
									wishRemoveLinks.forEach(link => {
										link.addRemoveWish(props.removeUrl);
									});
								}, { once: true });
							}

						} else {
							this.classList.remove(props.activeClass);
							const wishAddLink = this.querySelector('.js-wish-add-link');
							if (wishAddLink) {
								wishAddLink.addEventListener('click', (event) => {
									event.preventDefault();
									element.addRemoveWish(props.addUrl);

									// Others for same media in same page
									const otherLinks = document.querySelectorAll('.js-wish-add-remove[data-status-url="' + props.statusUrl + '"]');
									const wishRemoveLinks = Array.from(otherLinks).filter(function (item) { return item != element; });
									wishRemoveLinks.forEach(link => {
										link.addRemoveWish(props.addUrl);
									});
								}, { once: true });
							}
						}
					})
					.catch(error => {
						console.error('Erreur lors de la requête :', error);
					});
			};
			element.updateStatus();
		});
	}
};
