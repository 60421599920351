// See accessibility recommandations https://www.w3.org/WAI/tutorials/menus/flyout/

export default new class Flyout {
	initSubMenus() {
		const menuItems = document.querySelectorAll('.js-has-submenu');
		Array.prototype.forEach.call(menuItems, function (el) {
			el.querySelector('.js-open-submenu').addEventListener("click", function () {
				if (!this.parentNode.classList.contains("open")) {
					const openSubmenus = document.querySelectorAll('.js-has-submenu.open');
					Array.prototype.forEach.call(openSubmenus, function (openEl) {
						openEl.classList.remove('open');
						openEl.querySelector('.js-open-submenu').setAttribute('aria-expanded', "false");
					});
					this.parentNode.classList.add('open');
					this.parentNode.querySelector('.js-open-submenu').setAttribute('aria-expanded', "true");
				} else {
					this.parentNode.classList.remove('open');
					this.parentNode.querySelector('.js-open-submenu').setAttribute('aria-expanded', "false");
				}
				if (el.getAttribute('data-overlay') === 'true') {
					document.querySelector('.js-overlay').classList.toggle('is-opened');
				}
			});
			// au clic en dehors du menu, on ferme le menu
			document.addEventListener('click', function (event) {
				if (!el.contains(event.target)) {
					el.classList.remove('open');
					el.querySelector('.js-open-submenu').setAttribute('aria-expanded', "false");
				}
				if (document.querySelectorAll('.js-has-submenu.open').length === 0) {
					document.querySelector('.js-overlay').classList.remove('is-opened');
				}
			});
		});
	}
};
