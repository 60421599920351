// This is the scss entry file
import "../styles/index.scss";

import Slideshow from './util/Slideshow';
import Masonry from './util/Masonry';
import Header from './util/Header';
import ModalForm from './util/ModalForm';
import Navigation from './util/Navigation';
import Flyout from './util/Flyout';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import Wishlist from "./util/Wishlist";
import Accordion from "./util/Accordion";
import TabsAutomatic from "./util/Tab";
import AudioPlayer from "./util/Audio";
import AutoSelect from './util/AutoSelect';

window.document.addEventListener("DOMContentLoaded", function () {
	Header.initScroll();
	Slideshow.initSlideshow();
	Masonry.initMasonry();
	Fancybox.bind("[data-fancybox]");
	ModalForm.initModalForms();
	tippy('.js-tooltip');
	Flyout.initSubMenus();
	Navigation.initResponsiveNav();
	Wishlist.initWishlistLinks();
	AudioPlayer.initializePlayer();

	// init accordions
	const accordions = document.querySelectorAll('.js-accordion-trigger');
	accordions.forEach((accordionEl) => {
		new Accordion(accordionEl);
	});

	// disappearing message after a while or on click
	const messages = document.querySelectorAll('.js-message');
	messages.forEach((message) => {
		const hideMessage = () => {
			message.classList.add('is-hidden');
			message.addEventListener('transitionend', function () {
				message.remove();
			});
		};

		setTimeout(hideMessage, 6000);

		message.addEventListener('click', (e) => {
			if (e.target.tagName !== 'A') {
				hideMessage();
			}
		});
	});

	// Smooth scrolling when clicking on a anchor link
	const anchors = document.querySelectorAll('a[href^="#"]:not([href="#"]):not([href="#main"]):not([href="#header"]):not([href="#footer"])');
	anchors.forEach(function (el) {
		el.addEventListener('click', function (e) {
			e.preventDefault();

			// Get target element
			const target = this.hash;

			document.querySelector(target).scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});

			// open accordion tracks
			if (target === '#tracks') {
				const tracks = document.getElementById('tracks');
				if (tracks && tracks.innerHTML !== '') {
					tracks.querySelector('.js-accordion-trigger').click();
				}
			}
		});
	});

	// Tabs
	const tablists = document.querySelectorAll('.js-tabs');
	for (var i = 0; i < tablists.length; i++) {
		new TabsAutomatic(tablists[i]);
	}

	// scrolling for anchor links coming from a different page
	const hash = window.location.hash;
	if (hash.length > 0) {
		window.scrollTo(0, 0);
		setTimeout(function () {
			document.querySelector(hash).scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}, 100);
	}

	// show more link
	const toggleMores = document.querySelectorAll('.js-toggle-more');
	toggleMores.forEach((toggle) => {
		const link = toggle.querySelector('.js-toggle-trigger');
		if (link) {
			link.addEventListener('click', function (event) {
				event.preventDefault();
				const content = toggle.querySelector('.js-toggle-content');
				content.style.display = (content.style.display === 'none') ? 'inherit' : 'none';
			});
		}
	});

	// JS params
	let selects = document.querySelectorAll(".js-url-param-select");

	selects.forEach(select => {
		new AutoSelect(select);
	});

	// Supprimer automatiquement les paramètres non souhaités au chargement de la page
	let paramsToRemove = ["page"];
	let url = new URL(window.location.href);
	let urlParams = new URLSearchParams(url.search);
	let modified = false;

	paramsToRemove.forEach(param => {
		if (urlParams.has(param)) {
			urlParams.delete(param);
			modified = true;
		}
	});

	if (modified) {
		url.search = urlParams.toString();
		window.history.replaceState({}, '', url.toString());
	}
});
