import { Fancybox } from "@fancyapps/ui";
var Promise = require('promise');

export default new class ModalForm {
	initModalForms() {
		// Open modal
		const modalLinks = document.querySelectorAll(".js-open-modal-dialog");
		const self = this;
		modalLinks.forEach(function (button, key) {
			button.addEventListener("click", function (event) {
				event.preventDefault();
				let urlObj = new URL(button.href);  // Assuming the button has an href for ajax
				urlObj.searchParams.set("popup", "1"); // Add popup param
				fetch(urlObj)
					.then(response => response.text())
					.then(html => {
						// Create the outer modal container
						const modalContainer = document.createElement("div");
						modalContainer.classList.add("js-modal", "fade");
						modalContainer.id = "formModal-" + key;
						modalContainer.tabIndex = "-1";
						modalContainer.setAttribute("aria-labelledby", "formModalLabel");

						// Insert the title and content from the html returned
						modalContainer.innerHTML = self.buildDialogFromHtml(html);

						// Initialize modal
						const fancybox = new Fancybox([
							{
								src: modalContainer,
								type: "html",
							},
						]);
						fancybox.on('reveal', function (fancybox, slide) {
							// Focus on first input when modal is shown
							fancybox.focus();

							self.handleModalFormSubmission(slide.el.querySelector('.js-modal'));
						});

					})
					.catch(error => console.error("Error loading modal content:", error));
			});
		});

		const modalFormContainers = document.querySelectorAll(".js-handle-modal-form");
		modalFormContainers.forEach(container => {
			self.handleModalFormSubmission(container);
		});
	}

	handleModalFormSubmission(modalContainer) {
		const forms = modalContainer.querySelectorAll("form");
		const self = this;

		if (!forms.length) return;
		forms.forEach(form => {
			form.addEventListener("submit", function (event) {
				event.preventDefault();

				fetch(form.action, {
					method: "POST",
					redirect: "manual",
					body: new FormData(form),
				})
					.then(response => {
						if (response.status === 0 || response.status === 301) {
							location.href = response.url;
						} else if (response.status === 204 || response.status === 422) {
							// 204: successful
							// 422: validation error
							// For these two status codes, the dialog is closed,
							// the page is refreshed and a success or error message is shown
							location.reload();
							return Promise.reject("No content. response in request's message");
						}
						return response.text();
					})
					.then(html => {
						const dialogContent = self.buildDialogFromHtml(html);
						if (dialogContent) {
							modalContainer.innerHTML = self.buildDialogFromHtml(html);

							const firstInput = modalContainer.querySelector("input:not([type='hidden'])");
							if (firstInput) {
								firstInput.focus();
								firstInput.select();
							}

							// Rebind form submission handler
							self.handleModalFormSubmission(modalContainer);

						} else {
							modalContainer.remove();
							location.reload();
						}
					})
					.catch(error => console.error("Error handling form submission:", error));
			});
		});
	}

	buildDialogFromHtml(html) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(html, "text/html");
		const title = doc.querySelector(".js-dialog-title");
		const content = doc.querySelector(".js-dialog-content");

		// Sélectionner la balise <form> si elle existe
		const form = content.querySelector("form");

		if (form) {
			// Modifier l'URL de l'attribut "action" du formulaire
			const formAction = form.getAttribute("action");
			if (formAction) {
				// Utiliser l'objet URL pour gérer l'ajout du paramètre
				const actionURL = new URL(formAction, window.location.origin);
				actionURL.searchParams.set("popup", "1");
				form.setAttribute("action", actionURL.toString());
			}
		}

		// Mettre à jour le contenu avec la nouvelle structure
		const updatedHTML = content.innerHTML;

		// Return modal structure only if content exists
		if (updatedHTML) {
			return `
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							${title ? title.innerHTML : ""}
							<button type="button" class="btn-close" aria-label="Fermer"></button>
						</div>
						<div class="modal-body">
							${content ? content.innerHTML : "<p></p>"}
						</div>
					</div>
				</div>
			`;
		} else {
			return null;
		}
	}


};
